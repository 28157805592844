import React from 'react';

import './index.css'
import { Font } from '../../components/font'
import { Image } from '../../components/image'
// import video1 from '../../assets/images/home_video.mp4'
import image1 from '../../assets/imagesV2/home.jpg'

export const Home = () => {
    return (
        <div className="home-center">
            <Image image={image1} alt="home" width="100%" opacity="1" minHeight="80%" maxHeight="80%"/>
            {/* <video className="home-video" loop autoPlay muted={true}> 
                <source src={video1} type="video/mp4" />
            </video> */}

            <div className="home-div">
                <div className="margin-bottom-26 margin-top-26">
                    <Font name="Enabling Leverage Through Partnerships" size="32px" font="open sans,sans-serif" color="#F3F3F3" weight="bold"/>
                </div>

                {/* <div className="margin-bottom-39">
                    <Font name="Through Partnerships" size="28px" font="open sans,sans-serif" color="#008AFC" weight="bold"/>
                </div> */}

                {/* <div className="margin-bottom-78">
                    <Font name="Be Trust Us" size="41px" font="open sans,sans-serif" weight="bold"/>
                </div> */}
            </div>
        </div>
      );
}