import React from 'react';

import './index.css'
import { Font } from '../../components/font'
import { Image } from '../../components/image'
import image1 from '../../assets/imagesV2/aboutUs.jpg'
// import image1 from '../../assets/images/aboutus_pic1.webp'
import Grid from '@material-ui/core/Grid';

export const About = () => {

    const paragraph1 = "MEI founded in 2009, is located in Singapore being a strategic supply chain hub. The primary business was trading and subsequently expanded to manufacturing of Printed Circuit Board Assembly (PCBA) and complete product assembly for our key customers, with our business partners in Singapore, Malaysia, Thailand and China."
    const paragraph2 = "We facilitates our customers to provide a total solution, based on customer requirement, from design, prototyping, production, testing and after-sales service achieving a viable value proposition."
    const paragraph3 = "We pride ourselves being a team that possesses a keen knowledge in lean manufacturing, Design for Six Sigma (DfSS), strategic supply chain and manufacturing management."
    const paragraph4 = "Our partners possess state-of-the-art equipment and quality inspection equipment to facilitate your assembly and quality requirements."

    return (
        <div className="about-center">
            <Image image={image1} alt="aboutUs" width="100%" opacity="1"/>

            <div className="about-div">
                <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph1} font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal"/>
                </p>

                <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph2} font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal"/>
                </p>

                <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph3} font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal"/>
                </p>

                <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph4} font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal"/>
                </p>
            </div>

            {/* <Grid item xs={12} md={6} className="about-content">
                <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph1} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>

                <p align="justify">
                    <Font name={paragraph2} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>


                <p align="justify">
                    <Font name={paragraph3} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>


                <p align="justify">
                    <Font name={paragraph4} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>
            </Grid> */}

            {/* <Grid item xs={12} md={6} className="about-image">
                <Image image={image1} alt="cooperation" width="100%" opacity="1"/>
            </Grid> */}
        </div>
      );
}