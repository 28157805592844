import React from 'react';

import './index.css'

export const NavigationBarItem = ({name, path}) => {
    return (
        <div>
          <a className="navigation-bar-item" href={path}>
            {name}
          </a>
        </div>
      );
}