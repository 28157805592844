import React from 'react';

import './index.css'
import { Font } from '../../components/font'
import { Image } from '../../components/image'
import image1 from '../../assets/imagesV2/partners.jpg'
// import image1 from '../../assets/images/aboutus_pic1.webp'
import Grid from '@material-ui/core/Grid';

export const Partners = () => {

    return (
        <div className="partner-center">
            <Image image={image1} alt="product" width="100%" opacity="1"/>

            <div className="partner-div">
                <p align="left" style={{marginTop: '0px', lineHeight: '1.4'}}>
                    <Font name="NationGate Solution (M) Sdn.Bhd." font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal" size="25px"/>
                    <br/>
                    <Font name="Penang, Malaysia" font="open sans,sans-serif" color="rgb(243,243,243)" weight="100" size="18px" fontStyle="italic"/>
                </p>

                <br/>

                <p align="left" style={{marginTop: '0px', lineHeight: '1.4'}}>
                    <Font name="Morrissey Technology Sdn.Bhd." font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal" size="25px"/>
                    <br/>
                    <Font name="Kedah, Malaysia" font="open sans,sans-serif" color="rgb(243,243,243)" weight="100" size="18px" fontStyle="italic"/>
                </p>

                <br/>

                <p align="left" style={{marginTop: '0px', lineHeight: '1.4'}}>
                    <Font name="PNE Industries LTD" font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal" size="25px"/>
                    <br/>
                    <Font name="Johor Bahru, Malaysia" font="open sans,sans-serif" color="rgb(243,243,243)" weight="100" size="18px" fontStyle="italic"/>
                </p>

                <br/>

                <p align="left" style={{marginTop: '0px', lineHeight: '1.4'}}>
                    <Font name="Mace Instrumentation Sdn.Bhd." font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal" size="25px"/>
                    <br/>
                    <Font name="Kedah, Malaysia" font="open sans,sans-serif" color="rgb(243,243,243)" weight="100" size="18px" fontStyle="italic"/>
                </p>

                {/* <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph2} font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal"/>
                </p>

                <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph3} font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal"/>
                </p> */}

            </div>

            {/* <Grid item xs={12} md={6} className="about-content">
                <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph1} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>

                <p align="justify">
                    <Font name={paragraph2} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>


                <p align="justify">
                    <Font name={paragraph3} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>


                <p align="justify">
                    <Font name={paragraph4} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>
            </Grid> */}

            {/* <Grid item xs={12} md={6} className="about-image">
                <Image image={image1} alt="cooperation" width="100%" opacity="1"/>
            </Grid> */}
        </div>
      );
}