import React from 'react';

import './index.css'
import { Font } from '../../components/font'
import Grid from '@material-ui/core/Grid';
// import PhoneIcon from '@material-ui/icons/Phone';
// import LocationCityIcon from '@material-ui/icons/LocationCity';
// import EmailIcon from '@material-ui/icons/Email';
import { Image } from '../../components/image'
import image1 from '../../assets/imagesV2/contactUs.jpg'
import sgOffice from '../../assets/images/mei_technologies_contact_pic2.PNG'

export const Contact = () => {

    return (
        <div className="">
            <Image image={image1} alt="aboutUs" width="100%" opacity="1" position="absolute" zIndex="-1"/>

            <div className="contact-div">
                <Grid container className="contact-center">
                    <Grid item xs={12} className="title">
                        <Font name="Contact Details" size="28px" font="open sans,sans-serif" color="#545454" weight="bold"/>
                    </Grid>

                    <Grid item xs={12} md={6} className="contact-content">
                        <p align="left" style={{marginTop: '1rem'}}>
                            <Font 
                                name="We will be glad to hear from you"
                                size="16px"
                                font="open sans,sans-serif" 
                                color="rgb(113,112,112)" 
                                weight="bold"
                            />
                        </p>

                        <p align="left" style={{marginTop: '1rem'}}>
                            <Font name="MEI TECHNOLOGY PTE LTD" size="20px" font="open sans,sans-serif" color="#545454" weight="bold"/>
                        </p>

                        <p align="left" style={{marginTop: '1rem'}}>
                            <Font name="Address : " size="16px" font="open sans,sans-serif" color="#545454" weight="bold"/>
                            <Font name="19 Kallang Avenue, #05-155,Singapore 339410" size="16px" font="open sans,sans-serif" color="#545454" weight="bold"/>
                        </p>

                        <p align="left" style={{marginTop: '1rem'}}>
                            <Font name="Contact Person : " size="16px" font="open sans,sans-serif" color="#545454" weight="bold"/>
                            <Font name="Nelson Soh:&thinsp;&thinsp;+65 9617 1172" size="16px" font="open sans,sans-serif" color="#545454" weight="bold"/>
                            <br />
                            <Font name="Contact Person : " size="16px" font="open sans,sans-serif" color="#545454" weight="bold" visibility="hidden"/>
                            <Font name="Cliff Lai:" size="16px" font="open sans,sans-serif" color="#545454" weight="bold" />
                            <Font name="aaaa" size="16px" font="open sans,sans-serif" color="#545454" weight="bold" visibility="hidden"/>
                            <Font name="+65 9844 8451" size="16px" font="open sans,sans-serif" color="#545454" weight="bold" paddingLeft="0.03rem"/>
                        </p>

                    </Grid>

                    <Grid item xs={12} md={6} className="contact-image">
                        <Image image={sgOffice} alt="location of singapore office" width="100%" opacity="1"/>
                    </Grid>

                    <Grid container className="contact-address">
                        <Grid item xs={12} md={6} className="contact-address-section" style={{}}>
                            <p align="left" style={{marginTop: '0rem'}}>
                                <Font name="MEI Technology Pte Ltd" size="20px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                                <br />
                                <Font name="Singapore Office" size="20px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                            </p>

                            <p align="left" style={{marginTop: '1rem'}}>
                                <Font name="Address: " size="14px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                                <Font name="19 Kallang Avenue, #05-155,Singapore 339410" size="14px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                            </p>

                            <p align="left" style={{marginTop: '1rem'}}>
                                <Font name="Phone: " size="14px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                                <Font name="+65 6298 0100" size="14px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                            </p>

                            {/* <p align="left" style={{marginTop: '1rem'}}>
                                <EmailIcon />
                                <Font name="Sales Email: " size="18px" font="open sans,sans-serif" color="#545454" weight="bold"/>
                                <Font name="enquiries@meitechnology.com" size="18px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                            </p> */}
                        </Grid>

                        <Grid item xs={12} md={6} className="contact-address-section">
                            <p align="left" style={{marginTop: '0rem'}}>
                                <Font name="MEI Technology Limited" size="20px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                                <br/>
                                <Font name="Hong Kong Office" size="20px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                            </p>

                            <p align="left" style={{marginTop: '1rem'}}>
                                <Font name="Address: " size="14px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                                <Font name="Unit 1303B, 13/F, Kowloon Building, 555 Nathan Road, Mongkok, Kowloon Hong Kong SAR" size="14px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                            </p>

                            <p align="left" style={{marginTop: '1rem'}}>
                                <Font name="Phone: " size="14px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                                <Font name="+852 9616 4440" size="14px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                            </p>

                            {/* <p align="left" style={{marginTop: '1rem'}}>
                                <EmailIcon />
                                <Font name="Sales Email: " size="18px" font="open sans,sans-serif" color="#545454" weight="bold"/>
                                <Font name="enquiries@meitechnology.com" size="18px" font="open sans,sans-serif" color="#545454" weight="normal"/>
                            </p> */}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
      );
}