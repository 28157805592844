import React from 'react';

import './index.css'

export const Image = ({image="/image/favicon.ico", alt="No description available", width="100%", height="auto", opacity="0.5", minHeight="100%", maxHeight="100%", position="undefined", zIndex="0"}) => {

    return (
        <img className="clip" src={image} alt={alt} width={width} height={height} style={{opacity: opacity, minHeight: minHeight, maxHeight: maxHeight, position: position, zIndex: zIndex}}/>
      );
}
