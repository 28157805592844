import React from 'react';

import { Layout } from './views/layout'
import { NavigationBar } from './components/navigation_bar'
import { Footer } from './components/footer'

function App() {
  return (
    <div>
      <NavigationBar/>
      
      <Layout/>

      <Footer name="MEI Technology Pte Ltd"/>
    </div>
  );
}

export default App;
