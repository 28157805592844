import React from 'react';

import './index.css'

export const Font = ({name="default", size="1.2rem", font="open sans,sans-serif", color="black", weight="bold", visibility="visible", fontStyle="normal", paddingLeft="undefined"}) => {

    return (
        <span style={{fontSize: size, fontFamily: font, color: color, fontWeight: weight, visibility: visibility, fontStyle: fontStyle, paddingLeft:paddingLeft}}>{name}</span>
      );
}
