import React from 'react';

import './index.css'

export const NavigationBarHeader = ({name, path, handleClick}) => {
    return (
        <div className="navigation-bar-header-container">
          <a className="navigation-bar-header" onClick={() => {handleClick(path)}}>
            {name}
          </a>
        </div>
      );
}