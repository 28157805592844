import React from 'react';

import './index.css'
import { Font } from '../../components/font'
import { Image } from '../../components/image'
import image1 from '../../assets/imagesV2/product.jpg'
// import image1 from '../../assets/images/aboutus_pic1.webp'
import Grid from '@material-ui/core/Grid';

export const Services = () => {

    const paragraph1 = "MEI Technology, with years of experience contract manufacturing, provides Full Turnkey Electronics Manufacturing and Supply Chain Solutions to leading companies in the Medical, Healthcare, Industrial Equipment & Machineries, Telecommunication Equipment, and IT Peripherals markets."
    const paragraph2 = "We understand each customers’ unique expectations and requirements. Leveraging on expertise and capabilities, and with long history of proven track record and successful operations in the region, we strives to exceed expectations and bring value to the businesses of our customers."

    return (
        <div className="service-center">
            <Image image={image1} alt="product" width="100%" opacity="1"/>

            <div className="service-div">
                <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph1} font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal"/>
                </p>

                <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph2} font="open sans,sans-serif" color="rgb(243,243,243)" weight="normal"/>
                </p>

            </div>

            {/* <Grid item xs={12} md={6} className="about-content">
                <p align="justify" style={{marginTop: '0px'}}>
                    <Font name={paragraph1} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>

                <p align="justify">
                    <Font name={paragraph2} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>


                <p align="justify">
                    <Font name={paragraph3} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>


                <p align="justify">
                    <Font name={paragraph4} font="open sans,sans-serif" color="rgb(113,112,112)" weight="normal"/>
                </p>
            </Grid> */}

            {/* <Grid item xs={12} md={6} className="about-image">
                <Image image={image1} alt="cooperation" width="100%" opacity="1"/>
            </Grid> */}
        </div>
      );
}