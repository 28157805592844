import React from 'react';
import { useHistory } from "react-router-dom";


import './index.css'
import { NavigationBarItem } from '../navigation_bar_items'
import { NavigationBarHeader } from '../navigation_bar_header'
import Menu from '../menu'
import logo from '../../assets/images/mei_technologies_logo.png'
import Grid from '@material-ui/core/Grid';

export const NavigationBar = () => {

    const partners = [
        {
            name: "NationGate",
            url: "http://www.nationgate.com.my/"
        },
        {
            name: "Morrissey",
            url: "http://www.morrissey-tech.net/"
        }                 
    ]

    const history = useHistory();

    function handleClick(path) {
        history.push(path);
    }

    return (
        <Grid id="navigation-bar" container className="site-header" style={{zIndex: '1000'}}>
            <Grid container className="container">
            <Grid item xs={12} md={6} style={{display:'flex', alignItems:'center'}}>
                <a style={{cursor:"pointer"}} onClick={() => {handleClick("/")}}>
                    <img src={logo} alt="MEI Technology Logo" width="142" height="67px" style={{marginLeft:'3rem'}}/>
                </a>
                <NavigationBarHeader name="MEI Technology" aria-controls="simple-menu" aria-haspopup="true" handleClick={(path)=>{handleClick(path)}} path="/"/>
            </Grid>
            {/* <Grid item xs={6} md={2}>
                <NavigationBarHeader name="MEI Technology" aria-controls="simple-menu" aria-haspopup="true" handleClick={(path)=>{handleClick(path)}} path="/"/>
            </Grid> */}
            <Grid container xs={12} md={6} spacing={0}>
                <div className="container-flex">
                    {/* <Grid container xs={2}>
                        <NavigationBarItem name="Home" aria-controls="simple-menu" aria-haspopup="true" handleClick={(path)=>{handleClick(path)}} path="/"/>
                    </Grid> */}

                    <NavigationBarItem name="About us" aria-controls="simple-menu" aria-haspopup="true" handleClick={(path)=>{handleClick(path)}} path="/aboutus"/>
                    <NavigationBarItem name="Product &amp; Services" aria-controls="simple-menu" aria-haspopup="true" handleClick={(path)=>{handleClick(path)}} path="/services"/>
                    {/* <Menu name="Partners" items={partners}/> */}
                    <NavigationBarItem name="Partners" aria-controls="simple-menu" aria-haspopup="true" handleClick={(path)=>{handleClick(path)}} path="/partners"/>
                    <NavigationBarItem name="Contact us" aria-controls="simple-menu" aria-haspopup="true" handleClick={(path)=>{handleClick(path)}} path="/contact"/>

                    {/* <Grid className="container-justify-center" container xs={3}>
                        <NavigationBarItem name="About us" aria-controls="simple-menu" aria-haspopup="true" handleClick={(path)=>{handleClick(path)}} path="/aboutus"/>
                    </Grid>

                    <Grid className="container-justify-center" container xs={3}>
                        <NavigationBarItem name="Product &amp; Services" aria-controls="simple-menu" aria-haspopup="true" handleClick={(path)=>{handleClick(path)}} path="/services"/>
                    </Grid>

                    <Grid className="container-justify-center" container xs={3}>
                        <Menu name="Partners" items={partners}/>
                    </Grid>

                    <Grid className="container-justify-center" container xs={3}>
                        <NavigationBarItem name="Contact us" aria-controls="simple-menu" aria-haspopup="true" handleClick={(path)=>{handleClick(path)}} path="/contact"/>
                    </Grid> */}
                </div>
            </Grid>
            </Grid>
        </Grid>
      );
}