import React from 'react';
import {
    Switch,
    Route,
  } from "react-router-dom";

import './index.css'
import { Home } from '../home'
import { About } from '../about'
import { Services } from '../servicesV2'
import { Partners } from '../partners'
import { Contact } from '../contact'

export const Layout = () => {

    return (
        <div>
            <Switch>
                <Route path="/aboutus">
                    <About />
                </Route>
                <Route path="/services">
                    <Services />
                </Route>
                <Route path="/contact">
                    <Contact />
                </Route>
                <Route path="/partners">
                    <Partners />
                </Route>
                {/* <Route path="/users">
                    <Users />
                </Route> */}
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </div>
      );
}