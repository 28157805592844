import React from 'react';

import './index.css'

export const Footer = ({name}) => {
    return (
        <div className='footer'>
            <span>{name}</span>
        </div>
      );
}